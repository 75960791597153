@import '../helpers/mixins.scss';
@import '../helpers/placeholders.scss';
@import '../helpers/variables.scss';


#Contact {
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
    height: 900px;

    // Laptop's picture
    .__background {
        position: relative;
        box-sizing: border-box;
        width: 50%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            box-sizing: border-box;
            position: relative;
            object-fit: cover;
        }
    }

    // Container with all information
    .__container {
        box-sizing: border-box;
        width: 50%;
        padding-left: 20px;
        .__infobox {
            width: 560px;
            position: relative;
            margin: 20px auto;
            .__title {
                @extend %titles;
                background-color: hsl(0, 0%, 0%, 0);
            }
            .__desc {
                font-family: aeonik;
                font-size: 18px;
                color: $silver;
                background-color: black;
                background-color: hsl(0, 0%, 0%, 0);
            }

            // Box with e-mail and phone number
            .__info {
                width: 350px;
                padding-left: 15px;
                margin: 30px;
                border: 6px double $silver; 
                // display
                .__phone-number,
                .__email {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    img {
                        height: 30px;
                    }
                    p {
                        margin: 15px;
                        font-family: roboto;            
                        font-size: 25px;
                        line-height: 30px;
                        color: $red-logo;
                        padding-left: 9px;
                    }
                }
            }

            // Submit form
            form {
                width: 500px;
                .__inputs {
                    display: flex;
                    flex-direction: column;
                    input {
                        color: $silver;
                        background-color: hsl(0, 0%, 0%);
                        width: 430px;
                        margin-bottom: 20px;
                        border-top: none;
                        border-right: none;
                        border-left: none;
                        border-bottom: 1px solid $silver;

                        @include inputs($line-height: 33px, $font-size: 22px);
                        &::placeholder {
                            color: hsl(0, 0%, 60%);
                        }
                    }
                }
                textarea {
                    background-color: black;
                    color: $silver;
                    box-sizing: border-box;
                    width: 100%;
                    border: 1px solid $silver;
                    padding: 8px;
                    border-radius: 20px;
                    
                    @include inputs($line-height: 33px, $font-size: 22px);
                }
                .__btn {
                    cursor: pointer;
                    background-color: black;
                    color: $red-logo;
                    position: relative;
                    font-size: 20px;
                    height: 40px;
                    $btn-width: 100px;
                    width: $btn-width;
                    left: calc(50% - $btn-width / 2);
                    margin-top: 20px;
                    border-radius: 8px;
                    border: 1px solid $silver;
                    &:hover {
                        color: $silver;
                        border-color: $red-logo;
                        transition: all 400ms;
                    }
                }
            }
            .__thanks {
                width: 100%;
                p {
                    font-family: aeonik;
                    font-size: 18px;
                    color: $silver;
                    background-color: hsl(0, 0%, 0%, 0);
                    padding-top: 48px;
                }
            }
        }
    }
}

@media (max-width: $threshold4) {
    #Contact {
        height: 1000px;
        display: flex;
        align-items: center;
        // Laptop's image
        .__background {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            max-width: none;
            opacity: 0.35;
            overflow: hidden;
            justify-content: end;
            img {
                position: relative;
                
                width: 100%;
            }
        }
        // Information
        .__container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);              
            z-index: 1;
            padding: 0;
            width: 100%;
            .__infobox {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .__title {
                    width: 100%;
                    text-align: center;
                }
                .__desc {
                    width: 100%;
                    text-align: center;
                }
                // Submit form
                form {
                    .__inputs {
                        display: flex;
                        align-items: center;
                        input {
                            background-color: transparent;
                        }
                    }
                    textarea {
                        background-color: transparent;
                    }
                }
            }
            .__thanks {
                p {
                    padding-top: 48px 16px 0;
                    text-align: center;
                }
            }
        }

    }
}

@media (max-width: $threshold1) {
    #Contact {
        height: 840px;
        // Container with all information
        .__container {
            .__infobox {
                margin: 0 auto;
                width: 280px;
                .__title {
                    width: 100%;
                    text-align: center;
                }
                .__desc {
                    width: 100%;
                    text-align: center;
                    margin: 0;
                }
                .__info {
                    width: 250px;
                    padding-left: 15px;
                    margin: 30px;
                    border: 4px double $silver; 
                    // display
                    .__phone-number,
                    .__email {
                        img {
                            height: 20px;
                        }
                        p {
                            font-size: 18px;
                            line-height: 22px;
                            padding-left: 4px;
                        }
                    }
                }
                // Submit form
                form {
                    width: 100%;
                    .__inputs {
                        input {
                            width: 100%;
                            @include inputs($line-height: 24px, $font-size: 16px);

                        }
                    }
                    textarea {
                        @include inputs($line-height: 24px, $font-size: 16px);
                    }
                    .__btn {
                        font-size: 16px;
                        $btn-width: 70px;
                        width: $btn-width;
                        left: calc(50% - $btn-width / 2);
                    }
                }
            }
        }
    }

}