@import '../helpers/variables.scss';

#Home {
    background-color: $black;
    height: $home-height; // temporary
    margin-top: $header-height;
    .__container {
        color: $white;
        display: flex;
        justify-content: space-between;
        height: $home-height;
        width: 750px;
        margin: 0 auto;
        
        .__info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 160px 0;
            .__title {
                height: 2em;
                font-family: aeonik;
                font-size: 40px;
                font-weight: 100;
                letter-spacing: -0.05em;
            }
            .__show {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .__beginning {
                    font-size: 20px;
                    text-align: center;
                    padding-right: 6px;
                }
                // Changing text of introduction
                .__wrapper {
                    p {
                        font-size: 20px;
                        text-align: left;
                        margin: 0;
                        line-height: 0;
                    }
                }
            }

            // Div with both buttons (contact and projects)
            .__buttons {
                margin-top: 60px;
                position: relative;
                z-index: 2; // z-index must have higher value than __box div from About section
                // The button
                a {
                    box-sizing: border-box;
                    height: $buttons-height;
                    padding: $buttons-height/5.5 16px;
                    color: $red-logo;
                    border: 1.5px solid $red-logo;
                    border-radius: $buttons-height / 2;
                    font-weight: 500;
                    text-decoration: none;
                    cursor: pointer;
                    
                    position: relative;
                    display: inline-block; // to make overflow: hidden; working
                    overflow: hidden;
                    z-index: 1;

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 0%;
                        height: 100%;
                        transition: all .4s;
                        background-color: $red-logo;
                        border-radius: 10px;
                        z-index: -1;
                    }
                    // Navlink to Contact section and it's pseudo-element child
                    &:nth-of-type(odd) {
                        margin-right: 10px;
                        &:before {
                            right: 0;
                        }
                    }
                    // Navlink to Projects section and it's pseudo-element child
                    &:nth-of-type(even) {
                        &:before {
                            left: 0;
                        }
                    }         
                    // The hover effect for both buttons   
                    &:hover {
                        color: $black;
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .__photo {
            height: $home-height;
            object-fit: cover;
            display: inline-block;
        }    
    }
}

@media(max-width: $threshold3) {
    .__container {
        box-sizing: border-box;
        padding: 0 40px;
    }
}

@media(max-width: $threshold2) {
    #Home {
        height: $home-height-threshold3;
        .__container {
            height: $home-height-threshold3;
            justify-content: space-around;
            width: 100%;
            .__info {
                padding: 80px 0 130px;
            }
            .__photo {
                display: none;
            }
        }
    }
}

@media(max-width: $threshold1) {
    #Home {
        height: $home-height-threshold1;
        .__container {
            height: $home-height-threshold1;
            justify-content: space-around;
            width: 300px;
            padding: 0;
            .__info {
                padding: 90px 0 140px;
                .__title {
                    font-size: 28px;
                }
                .__show {
                    .__beginning {
                        font-size: 16px;
                    }
                    .__wrapper {
                        p {
                            font-size: 16px;
                        }
                    }
                }
                .__buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-items: start;
                    justify-content: space-between;
                    margin-top: 40px;
                    height: 100px;
                }
            }
        }
    }
}