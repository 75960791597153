@import '../../helpers/placeholders.scss';
@import '../../helpers/variables.scss';

#Projects {
    height: 600px;
    padding: 48px 0;
    background-color: $black;
    border-top: 1px solid $red-logo;
    border-bottom: 1px solid $red-logo;
    h1 {
        @extend %titles; 
        display: block;
        text-align: center;
        padding: 24px 48px 90px;
        margin: 0;
    }
    .__cards {
        display: flex;
        justify-content: space-evenly;
        overflow: hidden;
    }
}

@media (max-width: $threshold5) {
    #Projects {
        height: 1050px;
        .__cards {
            width: 100%;
            max-width: 1200px;
            box-sizing: border-box;
            margin: 0 auto;
            display: grid;
            grid-template: repeat(2, 50%) / repeat(2, 50%);
            justify-items: center;
            align-items: center;
            padding-bottom: 100px; // Due to {overflow: hidden} in .__cards and {top: 100px} in .Card, the title of the lowest card is hidden, the padding makes space for showing it

            // Position of "Card" components, the rest of styling in Card.scss
            .Card {
                &:nth-child(even) {
                    position: relative;
                    top: 100px;
                }
            }
        }
    }
    
}

@media (max-width: $threshold2) {
    #Projects {
        height: 1900px;
        h1 {
            font-family: roboto;
            font-size: 40px;
            text-decoration: underline solid $silver 1px;
            text-underline-offset: 8px;   
            color: $red-logo;
            display: block;
            text-align: center;
            padding: 24px 0 32px;
        }
        .__cards {
            display: flex;
            flex-direction: column;
            // Position of "Card" components, the rest of styling in Card.scss
            .Card {
                margin-top: 40px;     
                &:nth-child(even) {
                    top: 0px;
                }
            }
        }
    }
}