@import '../helpers/variables.scss';

#About {
  .__wallpaper1 {
    background-image: url('../../assets/about/computers.webp');
    background-position: center;
    height: 100vh;
  }
  .__box {
    // border: 1px solid red;
    height: 300px;
    width: 100%;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    z-index: 1;
    .__text {
      margin: 0 auto 0;
      padding: 10px 10px;
      backdrop-filter: blur(30px);
      width: 370px;
      border-radius: 20px;
      border: 0.5px solid orange;
      .__text-animated {
        font-family: aeonik, monospace;
        text-align: left;
        color: white;
        overflow: hidden;
        text-wrap: wrap;
        font-size: 1.4rem;
        width: 100%; // Change for 0
      }
    }
  }
}

@media (max-width: $threshold1) {
  #About {
    .__box {
      .__text {
        max-width: 300px;
        .__text-animated {
          font-size: 1rem
        }
      }
    }
  }
}