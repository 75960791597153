@import '../helpers/variables.scss';

#Footer {
    border-top: 1px solid $red-logo;
    height: 51px;
    background-color: $black;
    position: relative;
    z-index: 1; // For hiding laptop's image
    p {
        color: $silver;
        font-family: aeonik;
        text-align: center;
    }
}

@media (max-width: $threshold1) {
    #Footer {
        height: 51px;
        p {
            font-size: 13px;
        }
    }
}