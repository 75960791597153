@import '../helpers/mixins.scss';
@import '../helpers/variables.scss';

#Header {
    position: fixed;
    z-index: 4;
    top: 0;
    border-bottom: 2px solid $red-logo;
    height: $header-height;
    width: 100vw;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .Header__logo {
        height: 100px;
        width: 330px;
        display: inline-block;
        cursor: pointer;
    }
    .Header__menu {
        list-style-type: none;
        padding-inline-start: 20px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 310px;
    }
    .Header__menu li {
        font-family: aeonik;
        font-size: 18px;
        display: inline-block;
        padding: 9px;
    }
    // Language flags
    .Header__icons {
        display: flex;
        width: 60px;
        // Menu btn
        .Header__icons__menu {
            display: none;
        }
        // Flag
        .Header__icons__flag {
            $height: 28px;
            height: $height;
            width: calc($height * 8 / 5);
            cursor: pointer;
        }
    }
    
}

@media(max-width: $threshold2) {
    #Header {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 20px;
        .Header__menu {
            position: absolute;
            z-index: -1;
            top: 114px;
            left: 0;
            width: 100vw;
            flex-direction: column;
            padding: 0;
            cursor: auto;
            overflow: hidden;
            li {
                background-color: $black;
                position: relative;
                box-sizing: border-box; 
                width: 100%;
                border-bottom: 0.1px solid $red-logo;
                height: $header-button-height;
                cursor: pointer;

                &:hover{
                    background-color: $silver;
                    color: $black;
                    font-weight: 1000;
                    letter-spacing: .5px;
                } 
            }
            li:nth-child(1) {
                z-index: -1;
            }
            li:nth-child(2) {
                z-index: -2;
            }
            li:nth-child(3) {
                z-index: -3;
            }
            li:nth-child(4) {
                z-index: -4;
            }
        }
        // Sliding in effect
        .Header__menu-shown {
            li {
                transform: translateX(0vw);
            }
            li:nth-child(1) {
                transition: transform $header-button-speed ease-out;
            }
            li:nth-child(2) {
                transition: transform $header-button-speed  ease-out $header-button-speed * 0.5;
            }
            li:nth-child(3) {
                transition: transform $header-button-speed  ease-out $header-button-speed;
            }
            li:nth-child(4) {
                transition: transform $header-button-speed  ease-out $header-button-speed * 1.5;
            }
        }
        .Header__menu-hidden {
            li {
                transform: translateX(100vw);
            }
            li:nth-child(1) {
                transition: transform $header-button-speed  ease-in $header-button-speed * 1.5;
            }
            li:nth-child(2) {
                transition: transform $header-button-speed  ease-in $header-button-speed;
            }
            li:nth-child(3) {
                transition: transform $header-button-speed  ease-in $header-button-speed * 0.5;
            }
            li:nth-child(4) {
                transition: transform $header-button-speed ease-in;
            }
        }
        .Header__icons {
            width: 90px;
            padding-right: 10px;
            // mixin with all menu's button styling
            @include proportions($width: 40px);
        }
    }
}

@media (max-width: $threshold1) {
    #Header {
        flex-direction: column;
        justify-content: space-evenly;
        height: 140px;
        padding: 0;
        .Header__logo {
            height: 80px;
            width: 240px;
        }
        .Header__menu {
            top: 124px;
        }    
        .Header__icons {
            width: 65px;
            padding: 0;
            $height: 20px;
            display: flex;
            justify-content: space-between;
            .Header__icons__flag {
                height: $height;
                width: calc($height * 8 / 5);
            }
            // mixin with all menu's button styling
            @include proportions($width: 30px);
        }
    }
}