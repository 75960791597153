@font-face {
    font-family: "archivo";
    src: url('../../assets/fonts/Archivo-Regular.ttf');
}
@font-face {
    font-family: "aeonik";
    src: url('../../assets/fonts/Aeonik-Pro-Light.ttf');
}
@font-face {
    font-family: roboto;
    src: url('../../assets/fonts/Roboto-Light.ttf');
}
@font-face {
    font-family: "geom";
    src: url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.eot");
    src: url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b0b8a10e9c97391e66297c8b5398984f.svg#Geom Graphic W03 Regular")format("svg");
}

// Colors
$silver: rgb(211,211,211);
$black: #19161A;
$white: hsl(0, 0%, 90%);
$red-logo: #FF4057;
$red-lighter: hsl(353, 100%, 70%);

// Animation time
$animations-time: 1s;

// Slider's parameters
$icon-width: 200px;
$slider-height: 150px;

// Header
$header-height: 130px;
$header-button-width: 40px;
$header-button-width-2: 20px;
$header-button-height: 39px;
$header-button-speed: 200ms;

// Home 
$home-height: 620px;
$home-height-threshold3: 500px;
$home-height-threshold1: 500px;
$buttons-height: 40px;

// Contact
$textarea__button-width: 100px;

// Media
$threshold1: 576px;
$threshold2: 768px;
$threshold3: 992px;
$threshold4: 1200px;
$threshold5: 1400px;

// *{border: 1px solid red} 